import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Hero from "../components/hero";
import Paragraphs from "../components/paragraphs";
import CtaBlock from "../components/cta-block";
import Button from "../components/button";

const Home = ({ data }) => {
  const {
    description,
    hero,
    introduction,
    ctaImageBlock
  } = data.markdownRemark.frontmatter;
  return (
    <Layout>
      <Seo
        title={hero.title}
        description={description}
        imagePath={hero.heroImage.publicURL}
      />
      <Hero
        fluid={hero.heroImage.childImageSharp.fluid}
        title={hero.title}
        subtitle={hero.subtitle}
        isHome
      />
      <Paragraphs
        heading={introduction.heading}
        paragraphs={introduction.paragraphs}
      />
      <Button
        url={introduction.button.link}
        name={introduction.button.name}
        standalone
      />
      <CtaBlock
        fluid={ctaImageBlock.image.childImageSharp.fluid}
        heading={ctaImageBlock.heading}
        text={ctaImageBlock.heading}
        ctaLink={ctaImageBlock.button.link}
        ctaName={ctaImageBlock.button.name}
      />
    </Layout>
  );
};

Home.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  }).isRequired
};

export default Home;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { templateKey: { eq: "home" } }) {
      frontmatter {
        description
        hero {
          heroImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          title
          subtitle
        }
        introduction {
          heading
          paragraphs
          button {
            name
            link
          }
        }
        ctaImageBlock {
          image {
            childImageSharp {
              fluid(maxWidth: 720) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          heading
          text
          button {
            name
            link
          }
        }
      }
    }
  }
`;
